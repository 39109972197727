import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import favicon from '../static/favicon-32x32.png';

const SEO = ({ location, ...props }) => (
  <StaticQuery
    query={detailsQuery}
    render={(data) => {
      const title = props.title
        ? `${props.title} | High Park Academy`
        : data.site.siteMetadata.title;
      const description =
        props.description || data.site.siteMetadata.description;

      const siteURL = location?.host
        ? `https://${location.host}`
        : data.site.siteMetadata.siteURL;
      const canonicalURL =
        location?.href || `${siteURL}${location?.pathname || ''}`;

      return (
        <Helmet
          htmlAttributes={{
            lang: 'en',
          }}
          title={title}
          link={[
            { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` },
            { rel: 'canonical', href: `${canonicalURL}` },
          ]}
          meta={[
            {
              name: 'description',
              content: description,
            },
            {
              'http-equiv': 'Content-Security-Policy',
              content: `frame-ancestors 'self'; base-uri 'self'`,
            },
            { property: 'og:title', content: title },
            { property: 'og:description', content: description },
            {
              property: 'og:image',
              content: `${siteURL}/images/logo.jpg`,
            },
            { property: 'og:url', content: canonicalURL },
            { property: 'og:locale', content: 'en_CA' },
            { property: 'og:type', content: 'website' },
          ]}
        >
          <script type="application/ld+json">{`{
"@context": "http://schema.org",
"@type": "Organization",
"url": "https://highparkacademy.com",
"logo": "https://highparkacademy.com/images/logo.jpg",
"name": "High Park Academy",
"address": {
  "@type": "PostalAddress",
  "streetAddress": "2250A Bloor St W",
  "addressLocality": "Toronto",
  "addressRegion": "ON",
  "postalCode": "M6S 1N6",
  "addressCountry": "CA"
},
"telephone": "+14165336186",
"email": "admin@highparkacademy.com",
"openingHoursSpecification": [
  {
    "@type": "OpeningHoursSpecification",
    "dayOfWeek": [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday"
    ],
    "opens": "15:00",
    "closes": "21:00"
  },
  {
    "@type": "OpeningHoursSpecification",
    "dayOfWeek": "Friday",
    "opens": "00:00",
    "closes": "00:00"
  },
  {
    "@type": "OpeningHoursSpecification",
    "dayOfWeek": [
      "Saturday",
      "Sunday"
    ],
    "opens": "11:00",
    "closes": "19:00"
  }
]
        }`}</script>
        </Helmet>
      );
    }}
  />
);

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        siteURL
        siteDomain
      }
    }
  }
`;
