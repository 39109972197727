import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';

const Footer = (props) => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      allFooterMenuJson {
        edges {
          node {
            weight
            url
            name
          }
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <footer className="footer">
      <div className="container">
        <div className="row mb-1 mt-1">
          <div className="col-12">
            <h4 className="footer-title">High Park Academy</h4>
            <div className="mt-1 mb-1 footer-text">
              <div className="footer-contact-1">
                <div>2250A Bloor Street West</div>
                <div>Toronto ON M6S 1N6</div>
              </div>
              <div className="divider ml-2 mr-2"></div>
              <div className="footer-contact-2">
                <div>
                  <a href="tel:416-533-6186">416-533-6186</a>
                </div>
                <div>
                  <a href="mailto:admin@highparkacademy.com">
                    admin@highparkacademy.com
                  </a>
                </div>
              </div>
            </div>
            <span
              className="footer-text"
              style={{
                display: 'none',
              }}
            >
              Illustrations by{' '}
              <a
                rel="noopener noreferer"
                href="https://icons8.com/illustrations/author/5c07e68d82bcbc0092519bb6"
              >
                Icons 8
              </a>
              .
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
